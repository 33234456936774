import api from 'config/api';
import { ApiError } from 'types/Global';
import { Profile } from 'types/User';

// export const usersBaseURL = 'https://boxboard-back-dev.cmpc-innovation.com/bff';
export const usersBaseURL = `${api.getBaseURL() || ''}`;
// export const usersBaseURL = 'http://localhost:3002';

export const getProfile = () => api.get<Profile, ApiError>(`${usersBaseURL}/newusers/me`);

export const enableUser = (id: number, enable: boolean) =>
  api.put(`${usersBaseURL}/enable-users/${id}`, {
    activeFlag: enable
  });

export default api;
