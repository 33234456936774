import i18next from 'i18next';

i18next.addResources('es', 'TrackingScreen', {
  title: 'Tracking',
  titleDetail: 'Detalle embarque',
  back: 'Volver',
  home: 'Inicio',
  trackingList: 'Listado de tracking',
  inProcess: 'En preparación',
  departure: 'Zarpe',
  inTransit: 'En tránsito a destino',
  tansshipmentPort: 'Transbordo',
  portOfDestination: 'Puerto de destino',
  containerDischange: 'Descarga de nave',
  delivery: 'Entregado',
  emptyEquipmentReturned: 'Devolución de cont.',
  completed: 'Completado',
  customers: 'Cliente',
  shipment: 'Embarque',
  container: 'N° Contenedores',
  departureDate: 'Fecha de zarpe',
  eta: 'ETA',
  planned: 'Previsto',
  viewDetails: 'Ver detalle',
  shipments: 'embarques',
  day: 'día',
  days: 'días',
  statusT: 'Estado',
  order: 'Pedido',
  dateRanges: 'Fecha de llegada',
  searchFilter: 'Filtro de búsqueda',
  clientCountry: 'País',
  market: 'Mercado',
  booking: 'Booking',
  orderBuy: 'OC referencia',
  indicateClientCountry: 'Indique país',
  indicateMarket: 'Indique mercado',
  indicateBooking: 'Indique booking',
  indicateOcReference: 'Indique OC referencia',
  indicateState: 'Indique estado',
  applyFilter: 'Aplicar filtro'
});

i18next.addResources('en', 'TrackingScreen', {
  title: 'Tracking',
  titleDetail: 'Shipment detail',
  back: 'Return',
  home: 'Home',
  trackingList: 'Tracking List',
  inProcess: 'In process',
  departure: 'Departure',
  inTransit: 'In Transit',
  tansshipmentPort: 'Transshipment port',
  portOfDestination: 'Port of destination',
  containerDischange: 'Container dischange',
  delivery: 'Delivery',
  emptyEquipmentReturned: 'Empty equipment returned',
  completed: 'Completed',
  customers: 'Customer',
  shipment: 'Shipment',
  container: 'Container N°',
  departureDate: 'Departure Date',
  eta: 'ETA',
  planned: 'Planned',
  viewDetails: 'View details',
  shipments: 'shipment',
  day: 'day',
  days: 'days',
  statusT: 'Status',
  order: 'Order',
  dateRanges: 'Date range',
  searchFilter: 'Search filters',
  clientCountry: 'Country',
  market: 'Market',
  booking: 'Booking',
  orderBuy: 'Purchase order-invoice',
  indicateClientCountry: 'Indicate country',
  indicateMarket: 'Indicate market',
  indicateBooking: 'Indicate booking',
  indicateOcReference: 'Indicate PO-invoice',
  indicateState: 'Indicate state',
  applyFilter: 'Apply filter'
});
