import { create, NETWORK_ERROR } from 'apisauce';
import { CamelcaseSerializer, SnakecaseSerializer } from 'cerealizr';

const deserializer = new CamelcaseSerializer();
const serializer = new SnakecaseSerializer();
export const AUTH_TOKEN_HEADER_NAME = 'Authorization';
export const AUTH_TOKENID_HEADER_NAME = 'resource-access';
export const AUTH_IGNORE = 'ignore-authentication';
export const baseURL = process.env.REACT_APP_API_BASE_BFF_URL;
export const baseOrdersURL = process.env.REACT_APP_API_BASE_ORDERS_URL;
export const baseURLBff = process.env.REACT_APP_API_BASE_BFF_URL;
export const COOLDOWN = 10;
export const STATUS_CODES = {
  ok: 200,
  emptyResponse: 204,
  partialContent: 206,
  badRequest: 400,
  unauthorized: 401,
  forbidden: 403,
  notFound: 404,
  misdirectedRequest: 421,
  serverError: 500
};

export const uploadApi = create({
  baseURL: '',
  timeout: 180000
});

const api = create({
  baseURL,
  timeout: 180000
});

export const apiOrders = create({
  baseURL: baseOrdersURL,
  timeout: 180000
});

export const apiBff = create({
  baseURL: baseURLBff,
  timeout: 180000
});
export const mockApi = create({
  baseURL: 'https://private-817f78-cmpcmock.apiary-mock.com',
  timeout: 15000
});
export const mockApiLocal = create({
  baseURL: 'http://localhost:3000',
  timeout: 15000
});
export const setMonitor = (callback: () => void, statusCode: number) => {
  api.addMonitor(response => {
    if (response.status === statusCode) {
      callback();
    }
  });
};
api.addMonitor(response => {
  if (response.problem === NETWORK_ERROR) {
    // TODO: These callbacks should only be called if no other callback was asigned for the response.
  }
});
api.addResponseTransform(response => {
  if (response.data) {
    response.data = deserializer.serialize(response.data);
  }
});
const PREVENT_CONTENT_TYPES = ['multipart/form-data'];
api.addRequestTransform(request => {
  if (request.params) {
    request.params = serializer.serialize(request.params);
  }
  const isAllowedContentType = PREVENT_CONTENT_TYPES.includes(request.headers['content-type']);
  if (!isAllowedContentType && request.data) {
    request.data = serializer.serialize(request.data);
  }
});
export default api;
