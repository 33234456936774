import i18next from 'i18next';

i18next.addResources('es', 'TrackingDetail', {
  titleDetail: 'Detalle embarque',
  back: 'Volver',
  trackingList: 'Listado de tracking',
  shipmentDetail: 'Detalle embarque',
  clientName: 'Nombre del Cliente',
  shippingCompany: 'Naviera',
  booking: 'Booking',
  bl: 'BL',
  eta: 'ETA',
  arrival: 'Arribo',
  day: 'día',
  days: 'días',
  shippingInfo: 'Información de embarque',
  order: 'Pedido',
  purchaseOrder: 'Orden de compra',
  dispatchedQty: 'Cant. despachado (ton.)',
  units: 'Unidades',
  dispatchWeek: 'Sem. despacho',
  transshipmentHistory: 'Historial de pedidos',
  date: 'Fecha',
  place: 'Lugar',
  container: 'Contenedores'
});

i18next.addResources('en', 'TrackingDetail', {
  titleDetail: 'Shipment detail',
  back: 'Back',
  trackingList: 'Tracking',
  shipmentDetail: 'Shipment detail',
  clientName: 'Client',
  shippingCompany: 'Shipping company',
  booking: 'Booking',
  bl: 'BL',
  eta: 'ETA',
  arrival: 'Arrival',
  day: 'day',
  days: 'days',
  shippingInfo: 'Shipping information',
  order: 'Order ID',
  purchaseOrder: 'Purchase order',
  dispatchedQty: 'Dispatched qty (ton.)',
  units: 'Unit',
  dispatchWeek: 'Dispatch week',
  transshipmentHistory: 'Transshipment history',
  date: 'Date',
  place: 'Spot',
  container: 'Container'
});
